/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif; /* Set the font to Inter */
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f1faee;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

/* Navbar */
.navbar {
  background: #1d3557;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar .logo {
  font-size: 2rem;
  font-weight: bold;
  color: #a8dadc;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.navbar a {
  text-decoration: none;
  color: #f1faee;
  font-weight: 500;
  font-size: 1.1rem;
}

.navbar a:hover {
  color: #a8dadc;
}

/* Logo Styling */
.logo {
  font-family: "Inter", sans-serif; /* Use Inter font */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff; /* White text */
  font-weight: bold;
  line-height: 1.2; /* Adjusted for better spacing */
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.logo-main {
  font-size: 3rem; /* Larger font for 'KUBICL' */
  color: #f1faee; /* Soft white color */
}

.logo-sub {
  font-size: 1.2rem; /* Slightly larger font for 'TECHNOLOGIES' */
  color: #a8dadc; /* Light blue color */
  margin-left: 0.5rem; /* Add slight spacing */
}

.logo:hover {
  text-shadow: 0 0 12px #a8dadc, 0 0 25px #457b9d;
  transition: text-shadow 0.3s ease-in-out;
}

/* Hamburger menu */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.hamburger .bar {
  background-color: #fff;
  height: 4px;
  width: 100%;
  transition: all 0.3s ease;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Navbar for mobile */
.nav {
  display: flex;
}

.nav ul {
  display: flex;
}

.nav.active ul {
  display: flex; /* Ensure items are flexibly aligned */
  flex-direction: column; /* Stack items vertically */
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: #1d3557;
  padding: 1rem;
  text-align: center;
  z-index: 1000; /* Ensure it appears above other elements */
}
.nav.active ul li {
  margin: 1rem 0; /* Add spacing between menu items */
}
.nav.active ul li a {
  color: #f1faee;
  font-size: 1.2rem;
  text-decoration: none;
}

/* Hero Section */
.hero {
  text-align: center;
  background: #1d3557;
  color: #fff;
  padding: 6rem 1rem;
  position: relative;
}

/* Hero Text Animation */

/* Hero Text Animation */
.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-family: "Inter", sans-serif;
  opacity: 0;
  transform: translateX(-50px); /* Start from 50px to the left */
  animation: fadeSlideLeft 1.5s ease-out forwards;
}

/* Keyframes for Fade and Slide Left Effect */
@keyframes fadeSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Initial left position */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Final position */
  }
}

.hero p {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 12px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  background: linear-gradient(45deg, #00c6ff, #0072ff);
  color: white;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}

.cta-button:hover {
  background: linear-gradient(45deg, #0072ff, #00c6ff);
  transform: translateY(-4px); /* Adds lift effect */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.cta-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Adds focus outline */
}

/* Content Sections */
.content-section {
  padding: 4rem 1rem;
  text-align: center;
  background: #a8dadc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.content-section:nth-child(even) {
  background: #f1faee;
}

/* General Section Heading Styles */
.content-section h2 {
  font-size: 3rem; /* Increase the font size */
  color: #1d3557; /* Professional dark blue color */
  font-weight: 700; /* Bold font */
  text-transform: uppercase; /* Make headings uppercase */
  letter-spacing: 2px; /* Add spacing between letters */
  margin-bottom: 2rem; /* Create space below the heading */
  font-family: "Inter", sans-serif; /* Professional, clean font */
  position: relative; /* To allow for decorative underline */
  display: inline-block;
}

/* Decorative Underline for Headings */
.content-section h2::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Position slightly below the heading */
  left: 50%; /* Center align */
  transform: translateX(-50%);
  width: 80px; /* Width of the underline */
  height: 4px; /* Thickness of the underline */
  background: #457b9d; /* Match with your theme color */
  border-radius: 2px; /* Slightly rounded edges */
}

/* Add hover effect for the headings (optional) */
.content-section h2:hover::after {
  width: 120px; /* Expand underline on hover */
  background: #a8dadc; /* Slightly lighter color for effect */
  transition: width 0.3s ease, background 0.3s ease;
}

/* About Section Styles */
#about {
  background-color: #a8dadc; /* Light background color for the about section */
  padding: 60px 0; /* Adds padding to top and bottom of the section */
  font-family: "Arial", sans-serif;
}

#about .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

#about h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #1d3557; /* Dark color for heading */
  text-transform: uppercase; /* Make heading uppercase */
}

.about-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.about-text {
  flex: 1;
  padding-right: 20px; /* Padding between text and image */
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
  font-weight: 500; /* Medium weight for better readability */
  text-align: justify; /* Justifies the paragraph for a clean, professional look */
  letter-spacing: 0.5px; /* Slight letter spacing for readability */
}

.about-text p::first-letter {
  font-size: 2.5rem; /* Makes the first letter larger */
  font-weight: bold;
  color: #0072ff; /* Color for the first letter to make it stand out */
}

.about-image {
  flex: 1;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-text p {
    font-size: 1rem; /* Smaller font size on mobile */
  }

  .about-image {
    margin-top: 20px; /* Space between image and text in mobile */
  }
}

/* Services Section */
.services {
  background: #1d3557;
  color: #fff;
}

.services h2 {
  font-size: 2.5rem;
}

.services-list {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

/* Service Card Hover Effect */
.service {
  background: #222;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

.service:hover {
  transform: translateY(-10px); /* Lift the card slightly */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Add a more prominent shadow */
  background-color: #1d3557; /* Change the background color */
}

.service h3 {
  font-size: 1.7rem;
  color: #a8dadc;
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.service:hover h3 {
  color: #f1faee; /* Change heading color on hover */
}

.service p {
  color: #ccc;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.service:hover p {
  color: #a8dadc; /* Change paragraph text color on hover */
}

/* Contact Section */
.contact {
  background: #f1faee;
  color: #333;
  text-align: center;
  padding: 4rem 1rem;
}

.contact p {
  font-size: 1.2rem;
}

/* Footer */
footer {
  background: #1d3557;
  color: #fff;
  text-align: center;
  padding: 2rem 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkedin-icon img {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  margin-bottom: 1rem; /* Space between logo and text */
  transition: transform 0.3s ease;
}

.linkedin-icon img:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

footer p {
  font-size: 1.1rem;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .navbar ul {
    display: none; /* Hide nav items by default */
  }
  .nav {
    display: none; /* Hide nav */
  }
  .nav.active {
    display: block; /* Show nav when active */
  }
  .hamburger {
    display: flex; /* Show hamburger icon */
  }
}
